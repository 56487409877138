@import '~bulma';
@import 'variables.scss';
@import 'store.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'homepage.scss';
@import 'category.scss';
@import 'pdp.scss';
@import 'cart.scss';
@import 'checkout.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap&family=Lato:wght@300&display=swap');

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: #666666 !important;
}

.App {
  /*background-color: #282c34;*/
  background-color: #ffffff;
  min-height: 60vh;
  color: #666666;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.checkout {
  cursor: pointer;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.logo {
  margin-right: 10px;
  vertical-align: middle;
}

.header {
  padding-top: 10px;
}

.footer {
  margin-top: auto;
}

.shopping-count {
  position: relative;
  top: -10px;
  left: -5px;
}

.top-banner {
  background: #3d9ce5;
  height: 50px;
  color: white;
}

.select{
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  padding-left: 2.5em;
  border: 1px solid rgba(10, 10, 10, 0.15);
  font-size: 1rem;
  height: 2.5em;
  vertical-align: top;
}
