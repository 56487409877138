.pdp {
    &-company {
        margin-top: -20px;
        margin-bottom: 10px;
        color: $medium-grey;
        font-family: Open Sans;
        font-size: 16px;
        line-height: 26px;
    }
    &-price {
        font-size: 1.4em;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        color: $black;
    }
    .add-to-cart {
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 400px;
        .button {
            border: 1px solid $black;
            background: $white;
            box-sizing: border-box;
            border-radius: 3px;
            &:hover {
                background-color: $black;
                color: $white;
                transition: all 0.5s ease;
            }
            strong {
                text-transform: uppercase;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }
    
    .buy-it-now {
        margin-top: 10px;
        margin-bottom: 10px;
        max-width: 400px;
        .button {
            border: 1px solid $black;
            background: $black;
            box-sizing: border-box;
            border-radius: 3px;
            &:hover {
                background-color: $blue;
                border: 1px solid $blue;
                color: $white;
                transition: all 0.5s ease;
            }
            strong {
                color: $white;
                text-transform: uppercase;
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }
    .description {
        margin-top: 20px;
    }
    .share {
        img {
            height: 61px;
        }
    }
    .gallery-thumbnails {
        img {
            width: 70px;
            margin-right: 15px;
        }
    } 
    .ck-display {
        margin: 20px 0px;
        max-width: 400px;
      }
}