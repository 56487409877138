.homepage-wrapper {
    margin-top: 10px;
    .banner2 {
        img {
            width: 100%;
        }
    }
    .shop-by-cat {
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: 40px;
        @include respond-below(md) {
            padding-left: inherit;
            padding-right: inherit;
        }
        .card {
            box-shadow: none;
            border: 1px solid rgba(204, 204, 204, .3);
            h3 {
                margin-bottom: 12px;
                font-size: 14px;
                font-weight: inherit;
            }
            img {
                padding: 5px;
                max-height: 125px;
                max-width: 125px;
            }
            ul {
                margin-left: 0;
                li {
                    font-size: 14px;
                    line-height: 22x;
                    list-style: none;
                }
            }
            .media {
                @include respond-below(md) {
                    justify-content: center;
                }
            }
        }
    }    
}